import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import CameraIcon from '@mui/icons-material/PhotoCamera';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import logo from './logo.png';

function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://mui.com/">
        Your Website
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const defaultTheme = createTheme();

export default function Album() {
  const [showWaitListForm, setShowWaitListForm] = React.useState(false);

  return (
    <>
      <main>
        <Box
          sx={{
            bgcolor: '001937',
            pt: 8,
            pb: 6,
          }}
        >
          <Container maxWidth="sm">
            <img src={logo} alt="logo" width="100%" style={{margin:"50px 0"}}/>
            <Typography variant="h2" align="center" color="#fff" style={{fontSize:"24px","marginBottom": "50px"}}paragraph>
              A revolutionary, new way to code. Hook it up to your Source Control tool of choice, and let it help you code.
            </Typography>
            <div style={{display:"flex", justifyContent:"center"}}>
              <div class="klaviyo-form-WTTUXM"></div>
            </div>
          </Container>
        </Box>
      </main>
      </>
  );
}